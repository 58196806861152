import { db, authentication } from "../firebase/firebase-config";
import { setDoc, getDoc, doc } from "firebase/firestore";

class UserService {
    constructor(uid) {
        this.userRef = doc(db, "users", uid)
        this.uid = uid;
    }
    async setUpProfile(userInfo) {
        const info = await getDoc(this.userRef);
        if(!info.exists()){
            await setDoc(this.userRef, userInfo);
            return null
        } else {
            return info.data()
        }
    }
    
    // async setUpAuth(auth){
    //     await updateDoc(this.userRef, auth);
    // }
    
    //HOW TO SAVE -->
    // const testSave = {
    //     profile: {
    //         profilePics: {
    //         }
    //     }
    // }
    // await setDoc(this.userRef, testSave, {merge: true})


    
    async getProfile(isA){
        const profileBase = {
            banner: "",
            bio: "",
            location: "",
            name: "",
            pic: "",
            website: "",
            analytics: {
                clicks: 0,
                followers: 0,
            }
        }

        const profile = await getDoc(this.userRef)
        const data = profile.data()
        var profileToReturn = isA ? data.profile.A : data.profile.B
        if(profileToReturn == null) {
            profileToReturn = profileBase
            await setDoc(this.userRef, data, {merge: true})
        }

        return profileToReturn;
    }

    async getUser(){
        const profile = await getDoc(this.userRef)
        return profile.data()
    }

    async getData(){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        return data.profile.data
    }

    async getProfilePics(){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        if(data.profile.data.pics)
            return data.profile.data.pics
        else
            return '[]'
    }

    async addProfilePic(links){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        const noDups = [...new Set(links)]
        data.profile.data.pics = noDups
        console.log(noDups)
        await setDoc(this.userRef, data)
        return data.profile.data.pics
    }

    async addName(names){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        const noDups = [...new Set(names)]
        data.profile.data.names = noDups
        console.log(noDups)
        await setDoc(this.userRef, data)
        return data.profile.data.names
    }

    async addLocation(locations){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        const noDups = [...new Set(locations)]
        data.profile.data.locations = noDups
        console.log(noDups)
        await setDoc(this.userRef, data)
        return data.profile.data.locations
    }

    async addBio(bios){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        const noDups = [...new Set(bios)]
        data.profile.data.bios = noDups
        console.log(noDups)
        await setDoc(this.userRef, data)
        return data.profile.data.bios
    }

    async addBanner(banners){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        const noDups = [...new Set(banners)];
        data.profile.data.banners = noDups
        console.log(noDups)
        await setDoc(this.userRef, data)
        return data.profile.data.banners
    }

    async addWebsite(websites){
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        const noDups = [...new Set(websites)]
        data.profile.data.websites = noDups
        console.log(noDups)
        await setDoc(this.userRef, data)
        return data.profile.data.websites
    }

    async setProfile(isA, pro) {
        const profile = await getDoc(this.userRef)
        const data = profile.data()
        if(isA){
            data.profile.A = pro
        }
        else{
            data.profile.B = pro
        }
        data.analytics.active = true
        await setDoc(this.userRef, data)
    }

    async setUserData(data){
        console.log(data.analytics)
        await setDoc(this.userRef, data)
    }

    async setUserPremium() {
        await authentication.currentUser?.getIdToken(true)
        const decodedToken = await authentication.currentUser?.getIdTokenResult()
        const userPremium = decodedToken?.claims?.stripeRole ? true : false
        return userPremium
    }

    resetCurrentFollowerFlag(value){
        if(value == true){
            var data = {
                analytics : {
                    reset_follower_count: true
                }
            }
        }
        else {
            var data = {
                analytics : {
                    reset_follower_count: false
                }
            }
        }
        return data
    }

}

export default UserService;