import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from '../helper/helper'

const active = window.localStorage.getItem('currentActive')
const activeDef = isEmpty(active) ? false : active
const switchFollowers = window.localStorage.getItem('currentSwitchFollowers')
const paramsDef = isEmpty(switchFollowers) ? 150 : switchFollowers

const initialState = {
    active: activeDef,
    activeProfile: window.localStorage.getItem('currentActiveProfile'),
    parameters: paramsDef
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveProfile: (state, action) => {
        state.active = action.payload.active
        window.localStorage.setItem('currentActive', state.active)
        state.activeProfile = action.payload.active_profile
        window.localStorage.setItem('currentActiveProfile', state.activeProfile)
        state.parameters = action.payload.switch_every
        window.localStorage.setItem('currentSwitchFollowers', state.parameters)
    },
    setLogoutProfile: (state, action) => {
        state.active = null
        window.localStorage.setItem('currentActive', state.active)
        state.activeProfile = null
        window.localStorage.setItem('currentActiveProfile', state.activeProfile)
        state.parameters = 0
        window.localStorage.setItem('currentSwitchFollowers', state.parameters)
    }
  }
});

export const { setActiveProfile, setLogoutProfile } = appSlice.actions

export const selectActiveState = state => state?.app?.active;
export const selectActiveProfile = state => state?.app?.activeProfile;
export const selectParams = state => state?.app?.parameters;

export default appSlice.reducer