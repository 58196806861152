import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    username: window.localStorage.getItem('currentUser'),
    uid: window.localStorage.getItem('currentUid'),
    userProfilePic: window.localStorage.getItem('currentProfilePic'),
    isPremium: window.localStorage.getItem('currentUserPremium')
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
        state.username = action.payload.username;
        window.localStorage.setItem('currentUser', state.username)
        state.userProfilePic = action.payload.userProfilePic;
        window.localStorage.setItem('currentProfilePic', state.userProfilePic)
        state.uid = action.payload.uid;
        window.localStorage.setItem('currentUid', state.uid)
        state.isPremium = action.payload.isPremium
        window.localStorage.setItem('currentUserPremium', state.isPremium)
    },
    setUserLogoutState: (state, action) => {
        state.username = null;
        window.localStorage.setItem('currentUser', null)
        state.userProfilePic = null;
        window.localStorage.setItem('currentProfilePic', null)
        state.uid = null;
        window.localStorage.setItem('currentUid', null)
        state.active = null
        window.localStorage.setItem('currentActive', null)
        state.activeProfile = null
        window.localStorage.setItem('currentActiveProfile', null)
        state.isPremium = false
        window.localStorage.setItem('currentUserPremium', state.isPremium)
        window.localStorage.setItem('initSubscribe', false)
        window.localStorage.getItem('initSubscribe')

    },
    setUserPremium: (state, action) => {
      state.isPremium = action.payload
      window.localStorage.setItem('currentUserPremium', state.isPremium)
    },
    setUserPic: (state, action) => {
      state.userProfilePic = action.payload.pic
      window.localStorage.setItem('currentProfilePic', state.userProfilePic)
    }
  }
});

export const { setActiveUser, setUserLogoutState, setUserPremium, setUserPic } = userSlice.actions

export const selectUsername = state => state?.user?.username;
export const selectUserPic = state => state?.user?.userProfilePic;
export const selectUID = state => state?.user?.uid;
export const selectUserPremium = state => state?.user?.isPremium;


export default userSlice.reducer