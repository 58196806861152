import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function MainLinks() {
    const  linkStyle = 'light med-text noHover nav-link black-text'
    return (
        <ul className="right">
            <li><NavLink to='/' className={linkStyle}>Home</NavLink></li>
            <li><HashLink smooth to='/#how-it-works' className={linkStyle}>How it works</HashLink></li>
            <li><HashLink smooth to='/#faq' className={linkStyle}>FAQ </HashLink></li>
        </ul>
      )
}

export default MainLinks