import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/auth/userSlice'
import profileReducer from '../features/profileSlice'
import thunk from 'redux-thunk'
import appReducer from '../features/appSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        profile: profileReducer,
        app: appReducer
    },
    middleware: [thunk]
})