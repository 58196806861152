import React from 'react'
import { NavLink } from 'react-router-dom'
import { selectUID, selectUserPic, selectUserPremium } from '../../features/auth/userSlice'
import { useSelector } from 'react-redux'
import useSignOut from '../../firebase/useSignOutUser'
import MainLinks from './MainLinks'
import { selectActiveProfile } from '../../features/appSlice'
import { isEmpty } from '../../helper/helper'
import { selectProfileA, selectProfileB } from '../../features/profileSlice'
import { MONTHLY } from '../../helper/constants'
import { createCheckoutSession } from '../../stripe/createCheckoutSession'

function SignedInLinks() {
  const signOut = useSignOut();
  const uid = useSelector(selectUID)
  const activeProfile = useSelector(selectActiveProfile)
  const userPic = useSelector(selectUserPic)
  const profileA = useSelector(selectProfileA) 
  const profileB = useSelector(selectProfileB) 
  const profile = activeProfile == 'A' ? profileA : activeProfile == 'B' ?  profileB : null
  const profilePic = isEmpty(profile?.pic) ? userPic : profile.pic
    const  linkStyle = 'light med-text noHover nav-link black-text sidenav-close'
    const userPremium = useSelector(selectUserPremium)
    const checkout = () => createCheckoutSession(uid, MONTHLY)
    return (
      <div>
    <ul className="left">
      {/* Sidenav profile pic */}
      {
          userPremium == true ? (
            <li><NavLink to='/' className="btn btn-floating hide-on-large-only dropdown-trigger sidenav-close" style={{backgroundImage: `url(${profilePic})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}><img src={profilePic} alt="Profile Pic" style={{height: '100%', width: '100%'}}/></NavLink></li>
            ) : (
              <li><NavLink onClick={() => {createCheckoutSession(uid, MONTHLY)}} className="btn btn-floating hide-on-large-only dropdown-trigger sidenav-close" style={{backgroundImage: `url(${profilePic})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}><img src={profilePic} alt="Profile Pic" style={{height: '100%', width: '100%'}}/></NavLink></li>
            )
      }
        <li className='hide-on-med-and-down'><MainLinks></MainLinks></li>
        <li className='hide-on-large-only sidenav-close'><NavLink to='/' className={linkStyle}>Home</NavLink></li>
        {/* <li><NavLink to='/app' className={`${linkStyle} hide-on-med-and-down`}>Dashboard</NavLink></li> */}
        <li><NavLink to='/app' className={`${linkStyle}`}>Dashboard</NavLink></li>
        {/* {
          userPremium == true ? (

          ) : (
            <li><NavLink onClick={() => {createCheckoutSession(uid, MONTHLY)}} className={`${linkStyle}`}>Dashboard</NavLink></li>
          )
        } */}
      {/* Nav profile pic */}
        <li><NavLink to='/' className="btn btn-floating hide-on-med-and-down" style={{backgroundImage: `url(${profilePic})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}><img src={profilePic} alt="Profile Pic" style={{height: '100%', width: '100%'}}/></NavLink></li>
      {/* Sidenav Logout */}
      {/* Nav profile pic */}
        <li><NavLink className={`btn waves-effect primary-bg white-text bold capitalize`}onClick={signOut}>Logout</NavLink></li>    
    </ul>
    </div>
  )
}

export default SignedInLinks