import React from 'react'
import { Link } from 'react-router-dom'

const Logo = (props) => {
  const color = props.white ? '#fff' : '#1A90D9'
  return (
    <div>
        <Link to='/' className='brand-logo extra-bold' style={{fontSize: '30px', color: color}}>twirp.ai</Link>
        {
          props.nav == true && (
          <a href="#" className='sidenav-trigger black-text right' data-target='mobile-nav'> <i style={{fontSize: "30px"}} className="material-icons">menu</i></a>
        )
        }
    </div>
  )
}

export default Logo