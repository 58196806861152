const EMPTY_PROFILE = {
    banner: '',
    bio: '',
    location: '',
    name: '',
    pic: '',
    website: '',
    anayltics: {
        clicks: 0,
        followers: 0,
    }
}

export const DEFAULT_PROFILE = {
    analytics: {
        active: false,
        active_profile: 'A',
        current_follower_count: 0,
        current_profile_clicks: 0,
        first_time: true,
        starting_follower_count: 0,
        starting_profile_clicks: 0,
        switch_every: 150,
    },
    auth: {
        date: '',
        secret: '',
        token: '',
    },
    date: '',
    displayName: '',
    email: '',
    last_run: '',
    profile: {
        A: EMPTY_PROFILE,
        B: EMPTY_PROFILE,
        data: {
            banners: '',
            bios: '',
            locations: '',
            names: '',
            pics: '',
            websites: ''
        }
    },
    userPic: '',
    username: ''
}

export const ONE_YEAR = 'price_1M1F7bDlwIHRumeJZiuFWAW8'
export const MONTHLY = 'price_1M1F7bDlwIHRumeJ8hH9tJmi'