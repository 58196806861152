import { db } from "../firebase/firebase-config";
import getStripe from "./stripe-config";
import { onSnapshot, addDoc, collection} from "firebase/firestore";

export async function createCheckoutSession(uid, priceTag){
    const checkoutSessionRef = collection(db, 'users', uid, 'checkout_sessions')
    const priceData = {
            price: priceTag,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        }
    const priceRef = await addDoc(checkoutSessionRef, priceData)

    onSnapshot(priceRef, async (snap) => {
        const { error, sessionId } = snap.data()
        if(error){
            console.log(`Error = ${error}`)
        }
        if(sessionId) {
            console.log(`Session Id = ${sessionId}`)
            const stripe = await getStripe();
            stripe.redirectToCheckout({sessionId})
        }
    })
}