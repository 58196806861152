import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { selectUID, selectUserPremium } from '../../features/auth/userSlice'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../helper/helper'
import { useUpdatePremium } from '../../firebase/useAuthenticateUser'

const SubscribeRoute = () => {

  // Add your own authentication on the below line.
    const uid = useSelector(selectUID)
    useUpdatePremium(uid)

    const premium = useSelector(selectUserPremium)
    console.log(uid)

    return !isEmpty(uid) && premium && (premium == 'false' || premium == false) ? <Outlet /> : <Navigate to="/" />;
}

export default SubscribeRoute