import React from 'react'
import useAuthenticateUser from '../../firebase/useAuthenticateUser' 
import MainLinks from './MainLinks'
import LoginButton from '../MainComponents/LoginButton'

function SignedOutLinks() {
    const signInWithTwitter = useAuthenticateUser();
    const indent = () => {
        return (
            <span>&#9;</span>
            
        )
    }
    return (
        <ul className="left">
            <li><MainLinks></MainLinks></li>
            <li>
                <LoginButton large={false} showCancel={false} style={'full-width ml-20'}/>
            </li>
        </ul>
      )
}

export default SignedOutLinks