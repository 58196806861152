import React from 'react'
import Break from '../Helper/Break';

const Steps = (props) => {
    const stepNum = props.stepNum;
    const headline = props.headline;
    const stepText = props.text;
    const img = props.img;
    return (
    <div>
        <div className="hide-on-large-only">
            <div className="center-align">
                <p>0{stepNum}</p>
                <h5 className="bold large-font">{headline}</h5>
                <p className="large-font">{stepText}</p>
                <img src={img} alt="" style={{width: '100%'}}/>
            </div>
        </div>
        <div className="hide-on-med-and-down">
        {
            props.textLeft == true ? (
                <div className="row ">
                    <div className="right-align col l6">
                        <p className="t-gray-d light">0{stepNum}</p>
                        <h5 className="extra-bold">{headline}</h5>
                        <p className="">{stepText}</p>
                    </div>
                    <div className="col l6 m12 s12 left-l-center-m">
                        <img src={img} alt="" style={{width: "100%", outline: 'solid', borderRadius: "5px"}}/>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col l6">
                        <div className="col l6 hide-on-large-only center-align">
                            <p className="t-gray-d light">0{stepNum}</p>
                            <h5 className="extra-bold">{headline}</h5>
                            <p className="">{stepText}</p>
                            <Break num={2}/>
                        </div>
                        <img src={img} alt="" style={{width: "100%", outline: 'solid', borderRadius: "5px"}}/>
                    </div>
                    <div className="left-align col l6 hide-on-med-and-down">
                        <p className="t-gray-d light">0{stepNum}</p>
                        <h5 className="extra-bold">{headline}</h5>
                        <p className="">{stepText}</p>
                    </div>
                </div>
        )
        }
        </div>

        {/* {
            props.textLeft == true ? (
                <div className="row ">
                    <div className="right-align col l6">
                        <p className="t-gray-d light right-l-center-m">Step {stepNum}</p>
                        <div className="row right-l-center-m">
                            <h5 className="extra-bold col l12 m12 s12">{headline}</h5>
                            <div className="col l2"></div>
                            <p className="col l10">{stepText}</p>
                        </div>
                    </div>
                    <div className="col l6 m12 s12 left-l-center-m">
                        <img src={img} alt="" style={{width: "100%", outline: 'solid', borderRadius: "5px"}}/>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col l6">
                        <div className="col l6 hide-on-large-only center-align">
                            <p className="t-gray-d light">Step {stepNum}</p>
                            <h5 className="extra-bold">{headline}</h5>
                            <p className="">{stepText}</p>
                            <Break num={2}/>
                        </div>
                        <img src={img} alt="" style={{width: "100%", outline: 'solid', borderRadius: "5px"}}/>
                    </div>
                    <div className="left-align col l6 hide-on-med-and-down">
                        <p className="t-gray-d light">Step {stepNum}</p>
                        <h5 className="extra-bold">{headline}</h5>
                        <p className="">{stepText}</p>
                    </div>
                </div>
        )
        } */}
    </div>
    )
}

export default Steps;
