import React from 'react'
import { useSelector } from 'react-redux'
import { selectUID } from '../../features/auth/userSlice'
import { MONTHLY } from '../../helper/constants'
import { createCheckoutSession } from '../../stripe/createCheckoutSession'
import Break from '../Helper/Break'
import mainGif from '../../assets/twirp.gif'

const Subscribe = () => {
    const uid = useSelector(selectUID)
    createCheckoutSession(uid, MONTHLY)
    return (
        <div className="dashboard container">
                <div className="row valign-wrapper hide-on-med-and-down">
                    <div className="col xl5 l5">
                        <h1 className="left-l-center-m extra-bold all-caps black-text">TWITTER A/B TESTING <br /><span className="extra-bold all-caps secondary-color drop-shadow">THAT JUST WORKS.</span> </h1>
                        <Break num={1}/>
                        <h5 className="left-l-center-m black-text">Build an engaged twitter audience and monetize it. Without creating new content.</h5>
                        <Break num={3}/>

                    </div>
                    <div className="col xl1 l1"></div>
                    <div className="col xl5 l5">
                        <img src={mainGif} alt="" className='materialboxed responsive-img' style={{ width: "100%", outline: 'solid', borderRadius: "5px", }} />
                    </div>
                </div>
                <div className='hide-on-large-only center-align'>
                    <h2 className="extra-bold all-caps">TWITTER A/B TESTING</h2>
                    <h1 className="extra-bold all-caps secondary-color drop-shadow">THAT JUST WORKS.</h1>
                    <div className="container">
                        <img src={mainGif} alt="" className='gif-m' style={{ width: "100%", outline: 'solid', borderRadius: "5px", }} />
                    </div>
                </div>
                <div className="hide-on-med-and-down"><Break num={2}/></div>
                <div className="hide-on-large-only"><Break num={1}/></div>
                <div className="container">
                    <div className="center-align">
                        <h3 className="extra-bold all-caps">Find your ideal twitter profile</h3>
                    </div>
      
                    <img src="https://i.imgur.com/5nDJW3Y.png" alt="" className='responsive-img'/>

                </div>
        </div>
    )
    }

export default Subscribe