import React from 'react'
import { randomString } from '../../helper/helper'

const Break = (props) => {
  const temp = Array(props.num).fill(undefined)
  return (
    <div>
      {
        temp?.map((t, i) => {
            const string = randomString()
            return(
              <br key={string + i}/>
            )
        }
        )
      }
    </div>
  )
}

export default Break