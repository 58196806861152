import React from 'react'
import Logo from '../MainComponents/Logo'
import { Link } from 'react-router-dom'
import Break from '../Helper/Break'
import { useSelector } from 'react-redux'
import { selectUserPremium } from '../../features/auth/userSlice'
import LoginButton from '../MainComponents/LoginButton'

const Footer = () => {
    // const uid = useSelector(selectUID)
    // const updatePremium = useUpdatePremium(uid)
    const userPremium = useSelector(selectUserPremium)

    return (
    <div style={{backgroundColor: "#1A90D9"}}>
      <div className="container center-align">
        <Break num={1}/>
        <Logo white={true} />
        <p style={{color: "white"}}><span>&copy;</span> Copyright 2022 twirp.ai</p>
        <LoginButton style={'w-40-m-and-up'}/>
        <div style={{fontSize: '10px', color: "white"}}>
        <p><Link to='/tos' className='interactive-link'>Terms of Service</Link> | <Link to='/privacy' className='interactive-link'>Privacy Policy</Link></p>
        {
                userPremium == true && (
                    <a href='https://billing.stripe.com/p/login/9AQ7tO1445abgJG7ss' className='interactive-link'>Manage Subscription</a>
                )
        }
        <Break num={2}/>
        </div>
        {/* <Break num={2}/>
          <div className="row">
              <div className="col xl4 l4 m12 s12 left-l-center-m">
                  <Logo />
              </div>
              <div className="col xl4 l4 m12 s12 center-align">
                  <p><span>&copy;</span> Copyright 2022<br />twirp.ai</p>
              </div>
              <div className="col xl4 l4 m12 s12 right-l-center-m">
                  <Link to='/tos' className='interactive-link'>Terms of Service</Link><br />
                  <Link to='/privacy' className='interactive-link'>Privacy Policy</Link><br />
                  {
                userPremium == true && (
                  <div className="col xl3 right-l-center-m">
                    <a href='https://billing.stripe.com/p/login/9AQ7tO1445abgJG7ss' className='interactive-link'>Manage Subscription</a>
                  </div>
                )
              }
              </div>
              <div className="col xl3">
              </div>


              <div className="col l6"></div>
          </div>
        <Break num={3}/> */}
      </div>
    </div>
  )
}

export default Footer