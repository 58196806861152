import { useDispatch } from "react-redux";
import { setActiveProfile } from "../features/appSlice";
import { setUserPremium } from "../features/auth/userSlice";
import { setAProfile, setBProfile } from "../features/profileSlice";
import UserService from "../services/UserService";
import { DEFAULT_PROFILE } from "./constants";

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
}

export function isEmpty(val){
    return (val === undefined || val == null || val.length <= 0 || val == 'undefined' || val == 'null' || val == '0' || val == '') ? true : false;
}

export const COMMA = '&#cm'

export function randomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&%$';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function resetProfile(data) {
    var auth = data.auth;
    var displayName = data.displayName;
    var email = data.email;
    var userPic = data.userPic;
    var username = data.username;
    var newProfile = DEFAULT_PROFILE;
    newProfile.auth = auth;
    newProfile.displayName = displayName;
    newProfile.email = email;
    newProfile.userPic = userPic;
    newProfile.username = username;
    return newProfile;
}

export async function UpdateDispatch(uid) {
    const dispatch = useDispatch();
    const userService = new UserService(uid)
    const data = await userService.getUser()
    
    var profileA = data.profile?.A
    var profileB = data.profile?.B
    const activeSubscription = await userService.setUserPremium()
    dispatch(setActiveProfile(data.analytics))
    dispatch(setUserPremium(activeSubscription))
    dispatch(setAProfile (profileA))
    dispatch(setBProfile (profileB))
}