import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateDispatch } from '../../app/actions/profileActions'
import { selectUID } from '../../features/auth/userSlice'
import { isEmpty } from '../../helper/helper'

const Update = () => {
    const uid = useSelector(selectUID)
    const update = useUpdateDispatch(uid)
    useEffect(() => {
        if(!isEmpty(uid)){
            update();
        }
    })
    return (
    <div>
    </div>
    )
}

export default Update