import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUsername } from '../../features/auth/userSlice'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import Logo from '../MainComponents/Logo'
// import { useUpdateDispatch } from '../../app/actions/profileActions'
import { isEmpty } from '../../helper/helper'
import M from 'materialize-css'

const Navbar = () => {
    const username = useSelector(selectUsername);
    const loggedIn = !isEmpty(username);
    useEffect(() => {
    //   const loggedIn = !isEmpty(username)
    //   if(loggedIn == true){
    //       updateDispatch();
    //   }
        var sidenav = document.querySelectorAll('.sidenav');
        M.Sidenav.init(sidenav, { duration: 200, edge: 'right' });
    })
    return (
        <div>
        <div className='navbar-fixed'>
            <nav className="nav-wrapper z-depth-0 white nav-height">
                <div className="container">
                    <Logo nav={true}/>
                    <div className="hide-on-med-and-down right">
                    {
                        isEmpty(username) == false ? (
                            <SignedInLinks></SignedInLinks>
                        ) : (
                            <SignedOutLinks></SignedOutLinks>
                        )
                    }
                    </div>
                </div>
            </nav>
        </div>
        <div className='sidenav left' id='mobile-nav'>
            {
                        username != null && username != 'null' ? (
                            <SignedInLinks></SignedInLinks>
                        ) : (
                            <SignedOutLinks></SignedOutLinks>
                        )
            }
        </div>
        </div>



    )
}

export default Navbar