import React from 'react'
import useAuthenticateUser from '../../firebase/useAuthenticateUser';
import { useSelector } from 'react-redux';
import { selectUsername } from '../../features/auth/userSlice';
import { isEmpty } from '../../helper/helper';

const LoginButton = (props) => {
    const signInWithTwitter = useAuthenticateUser();
    const indent = props.indent
    const largeButton = props.large == true ? 'btn-large med-text' : ''
    const buttonStyle = props.large == true ? {padding: "0 5rem 0 4rem"} : {}
    const logoStyle = props.large == true ? {marginRight: "1rem", fontSize: "1.5rem"} : {fontSize: "1rem"}
    const username = useSelector(selectUsername);
    const loggedIn = !isEmpty(username);
    const customStyle = props.style;
    return (
        <div>
            {
                !loggedIn && (
                    <button className={`btn waves-effect white-text bold capitalize ${largeButton} rounded n-red-shadow ${customStyle}`} style={buttonStyle} onClick={signInWithTwitter}>Try it for free</button> 
                    // <FaTwitter style={logoStyle}/>{indent}
                )
            }
            {
                !loggedIn && props.showCancel && (
                    <div>
                        <h6>Cancel anytime</h6>
                    </div>
                )
            }
        </div>
  )
}

export default LoginButton