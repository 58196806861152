import { authentication } from './firebase-config'
import { signInWithPopup, TwitterAuthProvider } from 'firebase/auth'
import UserService from '../services/UserService'
import { useDispatch } from 'react-redux'
import { setActiveUser, setUserPremium } from '../features/auth/userSlice'
import { setAProfile, setBProfile } from '../features/profileSlice'
import { setActiveProfile } from '../features/appSlice'
import { DEFAULT_PROFILE } from '../helper/constants'

const useAuthenticateUser = () => {
    const dispatch = useDispatch()
    const username = window.localStorage.getItem('currentUser')
    const authenticateUser = () => {
        if (username == 'null' || username == null) {
            const provider = new TwitterAuthProvider();
            console.log('sign in')
            signInWithPopup(authentication, provider)
            .then((result) => {
                // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                // You can use these server side with your app's credentials to access the Twitter API.
                window.localStorage.setItem('currentUid', result.user.uid)
                const credential = TwitterAuthProvider.credentialFromResult(result);
                setUpUserProfile(result, credential);
                //   setUpAuthProfile(result, credential);
              console.log(result);
            }).catch((error) => {
              console.log(error)
            });
        }
    }

    const setUpUserProfile = async (res, cred) => {
        const userService = new UserService(res.user.uid);
        var newProfile = DEFAULT_PROFILE;
        newProfile.userPic = res.user.photoURL;
        newProfile.displayName = res.user.displayName;
        newProfile.username = res._tokenResponse.screenName;
        newProfile.date = new Date();
        newProfile.auth.token = cred.accessToken;
        newProfile.auth.secret = cred.secret;
        newProfile.auth.date = new Date();
        const userInfo = newProfile;
        const data = await userService.setUpProfile(userInfo);
        const premium = await userService.setUserPremium();
        // const premium = await userService.setUserPremium()
        // console.log(`Premium: ${premium}`)
        // if(data != null){
        //     dispatch(setActiveUser({
        //         username: res.user.displayName,
        //         userProfilePic: res.user.photoURL,
        //         uid: res.user.uid,
        //         isPremium: premium
        //     }))
        //     dispatch(setLoginProfile(userInfo.profile))
        //     dispatch(setActiveProfile(userInfo.analytics))
        // }
        await updateDispatch(res, userInfo);
        redirect();
    }

    const updateDispatch = async (res, data) => {
        const userService = new UserService(res.user.uid);
        const activeSubscription = await userService.setUserPremium()
        console.log(data);
        dispatch(setActiveUser({
            username: res.user.displayName,
            userProfilePic: res.user.photoURL,
            uid: res.user.uid,
        }))
        dispatch(setActiveProfile(data.analytics))
        dispatch(setUserPremium(activeSubscription))
        dispatch(setAProfile (data.profile.A))
        dispatch(setBProfile (data.profile.B))
    }

    // const setUpAuthProfile = async (res, cred) => {
    //     const userService = new UserService(res.user.uid);
    //     const authInfo = {
    //         auth: {
    //             token: cred.accessToken,
    //             secret: cred.secret,
    //             date: new Date(),
    //         }
    //     }
    //     await userService.setUpAuth(authInfo);
    // }

    return authenticateUser;
}


export default useAuthenticateUser;

export const useUpdatePremium = (uid) => {
    const dispatch = useDispatch()
    const updatePremium = async (uid) => {
        const userService = new UserService(uid);
        const premium = await userService.setUserPremium()
        dispatch(setUserPremium(premium))
    }

    return updatePremium
}

export const redirect = () => {
    window.location = '/app'
}