import React, { Component } from 'react'
import Faq from './Faq'
import Testimonial from './Testimonial'
import LoginButton from '../MainComponents/LoginButton'
import mainGif from '../../assets/twirp.gif'
import Steps from './Steps'
import M from 'materialize-css'

class Home extends Component {
    componentDidMount() {
        var elems = document.querySelectorAll(`.carousel`);
        var elem = elems[0]
        elems.forEach((e) => {
            if(e.id.includes('testimonial')){
                elem = e
            }
        })
        M.Carousel.init(elem, {
            duration: 200,
            indicators: true,
            noWrap: true,
            height: 100,
            });
    }
    render(){
        const test1 = {
            msg: 'Twitter is one of the best ways to drive traffic in 2023, and Twirp makes it easy to see which profile will convert the most. We added over 2,000 followers since we started using twirp',
            person: 'Alex Berman',
            img: "https://pbs.twimg.com/profile_images/1568241697984765956/P-Hu6uTW_400x400.jpg",
            handle: "@alxberman",
            headline: 'Instant increase in conversions'

        }
        const test2 = {
            msg: "It's really satisfying to see the different variations of your profile and how they perform. Now that I have this, growing my Twitter following will be a cake walk",
            person: 'Inexperienced Developer',
            img: "https://pbs.twimg.com/profile_images/1589639179629101056/-Nnfg4yO_400x400.jpg",
            handle: "@InexpDeveloper",
            headline: 'Best data analytics'
        }


        //SHOULD HAVE 10
        const trustedByImgs = [
            "https://pbs.twimg.com/profile_images/1404569623719186440/l2T1Fump_400x400.jpg", //1
            "https://pbs.twimg.com/profile_images/1530912594848100353/ECZoCe0G_400x400.jpg", //2
            "https://pbs.twimg.com/profile_images/1586893240707457025/WZHElwzB_400x400.jpg", //3
            "https://pbs.twimg.com/profile_images/1568241697984765956/P-Hu6uTW_400x400.jpg", //4
            "https://pbs.twimg.com/profile_images/1558205764375703552/-oEvkwQ0_400x400.jpg", //5
            "https://pbs.twimg.com/profile_images/1580662445198036992/5xkTJcMw_400x400.jpg", //6
        ]
        return(
            <div>            
                <div className="dashboard container">
                    <div className="row l-left-m-center">
                        <div className="col l6 s12">
                            <h1 className='capitalize bold'>Twitter A/B Testing <br /><span className="primary-txt">That Just Works.</span> </h1>
                            <p>Build an engaged twitter audience and monetize it. Without creating new content.</p>
                            <LoginButton style={'w-40-m-and-up'}/>
                        </div>
                        <div className="col l6 s12">
                            <img src={mainGif} alt="" className='intro-gif' style={{ width: "100%"}} />
                        </div>
                    </div>
                </div>
                <div className='secondary-bg'>
                    <div className="container">
                        <div className="center-align">
                            <h1 className="bold title-top">Test <span className="primary-txt">unlimited variations</span>  in order to find your <span className="primary-txt">ideal Twitter profile</span></h1>
                            <div className="title-btm">
                                <img src="https://i.imgur.com/5nDJW3Y.png" alt="" className='responsive-img'/>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className="container">
                        <div className="center-align">
                                    <h1 className="bold">It's <span className="primary-txt">easy</span> to get started</h1>
                                </div>
                                <Steps stepNum={1} headline={'Connect your account'} text={'Log into Twitter with one click to begin the process of finding your highest converting profile'} img={"https://i.imgur.com/nAB7GlY.jpg"} textLeft={true}/>
                                <Steps stepNum={2} headline={'Setup your tests'} text={'Upload your ideal profile pictures, bios, names, and banners and twirp will find the best one'} img={"https://i.imgur.com/N9KHna1.png"} textLeft={false}/>
                                <Steps stepNum={3} headline={'Lock it in!'} text={'Once you have a winning test, hit save and enjoy your new high converting Twitter profile. As viewers see your Twitter profile and start to follow, everything is tracked and tested'} img={"https://i.imgur.com/Ij89kqh.png"} textLeft={true}/>
                                <Steps stepNum={4} headline={'Gather data automatically'} text={'This A/B to Z testing will combine all the elements and continue testing until you have the greatest profile possible. Once optimized you will have more followers than ever!'} img={"https://i.imgur.com/BEUjMy9.png"} textLeft={false}/>
                        </div>
                </div>
                <div className="secondary-bg">
                    <div className="container" >
                        <h1 className="bold center-align title-top">What <span className="primary-txt"> our users</span> have to say</h1>
                        <div className="carousel carousel-min" id="testimonial">
                            <div className="carousel-item"><Testimonial info={test1}/></div>
                            <div className="carousel-item"><Testimonial info={test2}/></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container">
                        <Faq />
                        <div className="title-top title-btm mw-1000 row">
                            <div className="col s12">
                                <Testimonial info={test2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        //     <div className="dashboard container">
        //         <div className="row valign-wrapper hide-on-med-and-down">
        //             <div className="col xl5 l5">
        //                 <h1 className="left-l-center-m extra-bold all-caps black-text">TWITTER A/B TESTING <br /><span className="extra-bold all-caps secondary-color drop-shadow">THAT JUST WORKS.</span> </h1>
        //                 <Break num={1}/>
        //                 <h5 className="left-l-center-m black-text">Build an engaged twitter audience and monetize it. Without creating new content.</h5>
        //                 <Break num={3}/>
        //                 <div className="center-align">
        //                     <LoginButton large={true} showCancel={true}/>
        //                 </div>
        //             </div>
        //             <div className="col xl1 l1"></div>
        //             <div className="col xl5 l5">
        //                 <img src={mainGif} alt="" className='materialboxed responsive-img' style={{ width: "100%", outline: 'solid', borderRadius: "5px", }} />
        //             </div>
        //         </div>
        //         <div className='hide-on-large-only center-align'>
        //             <h2 className="extra-bold all-caps">TWITTER A/B TESTING</h2>
        //             <h1 className="extra-bold all-caps secondary-color drop-shadow">THAT JUST WORKS.</h1>
        //             <LoginButton large={true} showCancel={true}/>
        //             <div className="container">
        //                 <img src={mainGif} alt="" className='gif-m' style={{ width: "100%", outline: 'solid', borderRadius: "5px", }} />
        //             </div>
        //         </div>
        //         <div className="hide-on-med-and-down"><Break num={2}/></div>
        //         <div className="hide-on-large-only"><Break num={1}/></div>
        //         <div className="container">
        //             <div className="center-align">
        //                 <h3 className="extra-bold all-caps">Find your ideal twitter profile</h3>
        //             </div>
      
        //             <img src="https://i.imgur.com/5nDJW3Y.png" alt="" className='responsive-img'/>

        //         </div>
        //         <Break num={3}/>
        //         <Testimonial info={test1}/>
        //         <Break num={2}/>
        //         <div className="row container">
        //             <div className="col xl6 l7 m12 ">
        //                 <h6 className="bold left-l-center-m">Trusted by Twitter pros including</h6>
        //             </div>
        //             <div className="col xl6 l5 m12 s12 left-l-center-m">
        //                     <div className="col m3 s2"></div>
        //                     {
        //                         trustedByImgs.map((img, i) => {
        //                             return(
        //                                 <div className="col l1 m1 s1" key={`trusted${i}`}>
        //                                     <img src={img} alt="" className="circle stroke" style={{height: "50px", width: '50px', border: '3px solid #fff'}} />
        //                                 </div>
        //                             )

        //                         })
        //                     }
        //                     <div className="col m3 s2"></div>
        //             </div>
        //         </div>
        //         <Break num={1}/>
        //         <div className="center-align">
        //             <LoginButton large={true} showCancel={true}/>
        //         </div>
        //         <div>
        //         <Break num={1}/>
        //         <div className="center-align" id='how-it-works'>
        //                 <h2><b>How it works:</b></h2>
        //         </div>
        //             <Steps stepNum={1} headline={'Connect your account'} text={'Log into Twitter with one click to begin the process of finding your highest converting profile'} img={"https://i.imgur.com/nAB7GlY.jpg"} textLeft={true}/>
        //             <Break num={1}/>
        //             <Steps stepNum={2} headline={'Setup your tests'} text={'Upload your ideal profile pictures, bios, names, and banners and twerp will find the best one'} img={"https://i.imgur.com/N9KHna1.png"} textLeft={false}/>
        //             <Break num={1}/>
        //             <Steps stepNum={3} headline={'Lock it in!'} text={'Once you have a winning test, hit save and enjoy your new high converting Twitter profile. As viewers see your Twitter profile and start to follow, everything is tracked and tested'} img={"https://i.imgur.com/Ij89kqh.png"} textLeft={true}/>
        //             <Break num={1}/>
        //             <Steps stepNum={4} headline={'Gather data automatically'} text={'This A/B to Z testing will combine all the elements and continue testing until you have the greatest profile possible. Once optimized you will have more followers than ever!'} img={"https://i.imgur.com/BEUjMy9.png"} textLeft={false}/>
        //             <Break num={2}/>
        //         <div className="center-align">
        //             <LoginButton large={true} showCancel={true}/>
        //         </div>
        //         <Break num={1}/>
        //             <Faq />

        //         </div>
        //         {/* <Break num={3}/>
        //         <Testimonial info={test2}/> */}
        //         <Break num={2}/>
        //         <div className="center-align">
        //             <LoginButton large={true} showCancel={true}/>
        //         </div>
        //         <Break num={2}/>
        //     </div>
        )
    }
}


export default Home