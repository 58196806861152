import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { selectUsername } from '../../features/auth/userSlice'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../helper/helper'

const PrivateRoute = () => {

  // Add your own authentication on the below line.
  const username = useSelector(selectUsername)
  const loggedIn = isEmpty(username) ? false : true
    return loggedIn ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute