import React, { Component, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './components/NavBar/Navbar'
import Home from './components/home/Home'
import Footer from './components/Details/Footer'
import './App.css'
import PrivateRoute from './components/Routes/PrivateRoute'
import SubscribeRoute from './components/Routes/SubscribeRoute'
import Subscribe from './components/payment/Subscribe'
import Update from './components/Helper/Update'

const MainApp = lazy(() => import('./components/MainApp/MainApp'));
const TOS = lazy(() => import('./components/Legal/TOS'))
const Privacy = lazy(() => import('./components/Legal/Privacy'))

  class App extends Component{
  render(){
    return (
      <Router>
        <div className="app">
          <Update />
          <Navbar />
          <Suspense fallback={<h1>Loading...</h1>}>
            <Routes>
                <Route exact path='/' element={<Home/>} />
              <Route path='/app' element={<PrivateRoute />}>
                <Route path='/app' element={<MainApp />} />
              </Route>
              <Route path='/tos' element={< TOS/>} />
              <Route path='/privacy' element={< Privacy/>} />
              <Route path='/subscribe' element={<SubscribeRoute />}>
                <Route path='/subscribe' element={< Subscribe/>} />
              </Route>
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </Router>
    ) 
  }
}

export default App