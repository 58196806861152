import { authentication } from './firebase-config'
import { useDispatch } from 'react-redux'
import { setUserLogoutState } from '../features/auth/userSlice'
import { setLogoutProfile } from '../features/appSlice'
import { setLogoutProfile as profileLogout } from '../features/profileSlice'

const useSignOut = () => {
    const dispatch = useDispatch()
    window.localStorage.getItem('currentUser')
    const signOutUser = () => {
        console.log('Sign out')
        dispatch(setUserLogoutState());
        dispatch(setLogoutProfile());
        dispatch(profileLogout());
        authentication.signOut().then(() => {
 
        }).catch((err) => alert(err.message))
    }
    return signOutUser
}

export default useSignOut