import React from 'react'
import Break from '../Helper/Break'

const Faq = () => {
  return (
    <div className="" id='faq'>
        <h1 className='center-align'><b>FAQ</b></h1>
        <div className="hide-on-large-only">
        </div>
        <div className="left-l-center-m">
            <h4 className='bold'>What is A/B Testing?</h4>
            <p>A/B testing is a method of comparing two versions of your Twitter page to see which one performs better. This will allow you to find the profile that gives you the highest number of followers.</p>
            <h4 className='bold'>Will I get banned?</h4>
            <p>No, your Twitter account will not get banned solely by using our software to automate A/B testing. We act according to the Twitter guidelines and have done extensive tests to make sure you are safe.</p>
            <h4 className='bold'>Will this work for me?</h4>
            <p>If you use Twitter for business or personal branding, or just want more followers, twirp is the best way to take advantage of all of the traffic you get. Imagine getting 10x more followers with the same content. That’s what you unlock by using twirp.</p>
            <h4 className='bold'>Why is this free?</h4>
            <p>Setting up your tests and starting the trial is free. We also cover the first five days of testing, after that the cost is $49 a month to continue twirping.</p>
            <h4 className='bold'>What results should I expect?</h4>
            <p>Our users have have seen as much as a 500% increase in the amount of followers to their Twitter account after using twirp.</p>
        </div>
    </div>
  )
}

export default Faq