import { useDispatch } from "react-redux"
import { setActiveProfile } from "../../features/appSlice";
import { setUserPremium } from "../../features/auth/userSlice";
import { setAProfile, setBProfile, setBanners, setBios, setNames, setPics, setLocations, setWebsites, setLoginProfile } from "../../features/profileSlice"
import { MONTHLY } from "../../helper/constants";
import { COMMA, isEmpty, resetProfile } from "../../helper/helper";
import UserService from "../../services/UserService"
import { createCheckoutSession } from "../../stripe/createCheckoutSession";

export function useAddProfilePic (uid) {
    const dispatch = useDispatch();
    const addProfilePic = async () => {
        var link = window.localStorage.getItem('picInput')
        link = link == null || link == 'null' ? '' : link 
        console.log(link)
        if(link.endsWith('png') || link.endsWith('jpg') || link.endsWith('jpeg')) {
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('pics')?.replace('[','').replace(']','')
            links = isEmpty(links) ? [] : links.split(',')
            links.push(link)
            dispatch(setPics({
                pics: links
            }))
            var db_links = await userService.addProfilePic(links)
            if(window.localStorage.getItem('pics') != db_links)
                window.localStorage.setItem('pics', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return addProfilePic

}

export function useRemoveProfilePic (uid) {
    const dispatch = useDispatch();
    const removeProfilePic = async () => {
        var index = window.localStorage.getItem('selectedPic')
        if(index != null && index != 'null'){
            console.log(links)
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('pics')?.replace('[','').replace(']','')
            links = isEmpty(links) ? [] : links.split(',')
            links.splice(index, 1)
            console.log(links)
            console.log("Removing index " + index)
            dispatch(setPics({
                pics: links
            }))
            var db_links = await userService.addProfilePic(links)
            if(window.localStorage.getItem('pics') != db_links)
                window.localStorage.setItem('pics', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return removeProfilePic
}

export function useAddProfileName (uid) {
    const dispatch = useDispatch();
    const addProfileName = async () => {
        var name = window.localStorage.getItem('nameInput')
        name = name == null || name == 'null' ? '' : name 
        console.log(name)
        if(name.length > 0 && name.length < 50){
            const userService = new UserService(uid)
            var names = window.localStorage.getItem('names')?.replace('[','').replace(']','')
            names = isEmpty(names) ? [] : names.split(',')
            console.log(names)
            names.push(name)
            dispatch(setNames({
                names: names
            }))
            var db_names = await userService.addName(names)
            if(window.localStorage.getItem('names') != db_names)
                window.localStorage.setItem('names', db_names)
        }
        else{
            console.log("Name too short or too long")
            console.log(name.length)
        }
    }

    return addProfileName

}

export function useRemoveProfileName (uid) {
    const dispatch = useDispatch();
    const removeProfileName = async () => {
        var index = window.localStorage.getItem('selectedName')
        if(index != null && index != 'null'){
            console.log(links)
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('names')?.replace('[','').replace(']','')
            links = isEmpty(links) ? [] : links.split(',')
            links.splice(index, 1)
            dispatch(setNames({
                names: links
            }))
            var db_links = await userService.addName(links)
            if(window.localStorage.getItem('names') != db_links)
                window.localStorage.setItem('names', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return removeProfileName
}

export function useAddLocation (uid) {
    const dispatch = useDispatch();
    const addProfileLocation = async () => {
        var name = window.localStorage.getItem('locationInput')
        name = name == null || name == 'null' ? '' : name 
        console.log(name)
        if(name.length > 0 && name.length < 50){
            const userService = new UserService(uid)
            var names = window.localStorage.getItem('locations')?.replace('[','').replace(']','')
            names = isEmpty(names) ? [] : names?.split(',')
            console.log(names)
            names.push(name)
            dispatch(setLocations({
                locations: names
            }))
            var db_names = await userService.addLocation(names)
            if(window.localStorage.getItem('locations') != db_names)
                window.localStorage.setItem('locations', db_names)
        }
        else{
            console.log("Name too short or too long")
            console.log(name.length)
        }
    }

    return addProfileLocation

}

export function useRemoveLocation (uid) {
    const dispatch = useDispatch();
    const removeProfileLocation = async () => {
        var index = window.localStorage.getItem('selectedLocation')
        if(index != null && index != 'null'){
            console.log(links)
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('locations').replace('[','').replace(']','')
            links = links.length <= 0 ? [] : links.split(',')
            links.splice(index, 1)
            dispatch(setLocations({
                locations: links
            }))
            var db_links = await userService.addLocation(links)
            if(window.localStorage.getItem('locations') != db_links)
                window.localStorage.setItem('locations', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return removeProfileLocation
}

export function useAddWebsite (uid) {
    const dispatch = useDispatch();
    const addProfileWebsite = async () => {
        var name = window.localStorage.getItem('websiteInput')
        name = name == null || name == 'null' ? '' : name 
        console.log(name)
        if(name.length > 0 && name.length < 60){
            const userService = new UserService(uid)
            var names = window.localStorage.getItem('websites')?.replace('[','').replace(']','')
            names = isEmpty(names) ? [] : names?.split(',')
            console.log(names)
            names.push(name)
            dispatch(setWebsites({
                websites: names
            }))
            var db_names = await userService.addWebsite(names)
            if(window.localStorage.getItem('websites') != db_names)
                window.localStorage.setItem('websites', db_names)
        }
        else{
            console.log("Name too short or too long")
            console.log(name.length)
        }
    }

    return addProfileWebsite

}

export function useRemoveWebsite (uid) {
    const dispatch = useDispatch();
    const removeProfileWebsite = async () => {
        var index = window.localStorage.getItem('selectedWebsite')
        if(index != null && index != 'null'){
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('websites').replace('[','').replace(']','')
            links = links.length <= 0 ? [] : links.split(',')
            links.splice(index, 1)
            dispatch(setWebsites({
                websites: links
            }))
            var db_links = await userService.addWebsite(links)
            if(window.localStorage.getItem('websites') != db_links)
                window.localStorage.setItem('websites', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return removeProfileWebsite
}

export function useAddProfileBio (uid) {
    const dispatch = useDispatch();
    const addProfileBio = async () => {
        var bio = window.localStorage.getItem('bioInput')
        bio = bio == null || bio == 'null' ? '' : bio
        bio = bio.replace(new RegExp(',','g'), COMMA)
        if(bio.length > 0 && bio.length <= 160){
            const userService = new UserService(uid)
            var bios = window.localStorage.getItem('bios')?.replace('[','').replace(']','')
            bios = isEmpty(bios) ? [] : bios.split(',')
            bios = bios.length > 0 && isEmpty(bios[0]) ? [] : bios;
            console.log(bios)
            bios.push(bio)
            dispatch(setBios({
                bios: bios
            }))
            var db_bios = await userService.addBio(bios)
            if(window.localStorage.getItem('bios') != db_bios)
                window.localStorage.setItem('bios', db_bios)
        }
        else{
            console.log("Bio too short or too long")
            console.log(bio.length)
        }
    }

    return addProfileBio

}

export function useRemoveProfileBio (uid) {
    const dispatch = useDispatch();
    const removeProfileBio = async () => {
        var index = window.localStorage.getItem('selectedBio')
        if(index != null && index != 'null'){
            console.log(links)
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('bios').replace('[','').replace(']','')
            links = links.length <= 0 ? [] : links.split(',')
            links.splice(index, 1)
            dispatch(setBios({
                bios: links
            }))
            var db_links = await userService.addBio(links)
            if(window.localStorage.getItem('bios') != db_links)
                window.localStorage.setItem('bios', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return removeProfileBio
}

export function useAddProfileBanner (uid) {
    const dispatch = useDispatch();
    const addProfileBanner = async () => {
        var link = window.localStorage.getItem('bannerInput')
        link = link == null || link == 'null' ? '' : link 
        console.log(link)
        if(link.endsWith('png') || link.endsWith('jpg')) {
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('banners')?.replace('[','').replace(']','')
            links = isEmpty(links) ? [] : links.split(',')
            links = links.length > 0 && isEmpty(links[0]) ? [] : links
            links.push(link)
            dispatch(setBanners({
                banners: links
            }))
            var db_links = await userService.addBanner(links)
            if(window.localStorage.getItem('banners') != db_links)
                window.localStorage.setItem('banners', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return addProfileBanner

}

export function useRemoveProfileBanner (uid) {
    const dispatch = useDispatch();
    const removeProfileBanner = async () => {
        var index = window.localStorage.getItem('selectedBanner')
        if(index != null && index != 'null'){
            console.log(links)
            const userService = new UserService(uid)
            var links = window.localStorage.getItem('banners').replace('[','').replace(']','')
            links = links.length <= 0 ? [] : links.split(',')
            links.splice(index, 1)
            console.log(links)
            console.log("Removing index " + index)
            dispatch(setBanners({
                banners: links
            }))
            var db_links = await userService.addBanner(links)
            if(window.localStorage.getItem('banners') != db_links)
                window.localStorage.setItem('banners', db_links)
        }
        else{
            console.log("Need a valid link")
        }
    }

    return removeProfileBanner
}

function getRandomItem(arr) {
        // get random index value
        const randomIndex = Math.floor(Math.random() * arr.length);

        // get random item
        const item = arr[randomIndex];
    
        return item;
}

export function useBuildProfiles(uid, active = true) {
    const dispatch = useDispatch();
    const buildProfiles = async() => {
        const userService = new UserService(uid)
        //Check for profiles
        const data = await userService.getUser()
        if (data.analytics == null){
            data.analytics = { active: true, activeProfile: 'A', switch_every: 150, first_time: true }
        }
        if(data.analytics.first_time == null){
            data = resetProfile(data)
        }
        if(data.analytics.reset_follower_count == null){
            data.analytics = { 
                active: data.analytics.active, 
                activeProfile: data.analytics.active_profile, 
                current_follower_count: data.analytics.current_follower_count,
                current_profile_clicks: data.analytics.current_profile_clicks,
                first_time: data.analytics.first_time,
                reset_follower_count: true,
                starting_follower_count: data.analytics.starting_follower_count,
                starting_profile_clicks: data.analytics.starting_profile_clicks,
                switch_every: 150,
            }
        }
        var profileA = data.profile.A;
        var profileB = data.profile.B;
        var analytics = {
            followers: 0,
            clicks: 0,
        }
        profileA.analytics = isEmpty(profileA.analytics) ? analytics : profileA.analytics
        profileB.analytics = isEmpty(profileB.analytics) ? analytics : profileB.analytics
        var profileAEffectiveness = isEmpty(profileA.analytics.clicks) ? 0 : profileA.analytics.followers / profileA.analytics.clicks
        var profileBEffectiveness = isEmpty(profileB.analytics.clicks) ? 0 : profileB.analytics.followers / profileB.analytics.clicks
        if(data.analytics.first_time){
            profileA = constructProfile(data.profile.data)
            profileB = constructProfile(data.profile.data)
        }
        else if(profileAEffectiveness < profileBEffectiveness){
            profileA = constructProfile(data.profile.data)
        }
        else{
            profileB = constructProfile(data.profile.data)
        }
        dispatch(setAProfile (profileA))
        dispatch(setBProfile (profileB))
        const activeProfile = 'A'
        const switch_every = 150
        dispatch(setActiveProfile({
            active: active,
            activeProfile: activeProfile,
            parameters: switch_every
        }))
        data.profile.A = profileA
        data.profile.B = profileB
        data.analytics.active = active
        data.analytics.active_profile = activeProfile
        data.analytics.switch_every = switch_every
        data.analytics.reset_follower_count = true
        await userService.setUserData(data)
        .then(() => {
            if(active)
                window.location.reload();
            else{
                createCheckoutSession(uid, MONTHLY)
            }

        })
        // await userService.resetCurrentFollowerFlag(true)
    }

    const constructProfile = (data) => {
        var pic = !isEmpty(data.pics) ? getRandomItem(data.pics) : ''
        var name = !isEmpty(data.names) ? getRandomItem(data.names) : '' 
        var bio = !isEmpty(data.bios) ? getRandomItem(data.bios) : '' 
        var location = !isEmpty(data.locations) ? getRandomItem(data.locations) : '' 
        var banner = !isEmpty(data.banners) ? getRandomItem(data.banners) : '' 
        var website = !isEmpty(data.websites) ? getRandomItem(data.websites) : '' 
        return {
            pic: pic,
            name: name,
            bio: bio,
            location: location,
            banner: banner,
            website: website,
            analytics: {
                clicks: 0,
                followers: 0,
            }
        }
    }
    return buildProfiles
}

export function useChangeData(uid) {
    const dispatch = useDispatch();
    const changeData = async() => {
        const userService = new UserService(uid)
        const data = await userService.getUser()
        if (data.analytics == null){
            data.analytics = { active: false, activeProfile: 'A', switch_every: 150, first_time: false }
        }
        data.analytics.active = false
        data.analytics.first_time = false
         
        dispatch(setActiveProfile({
            active: data.analytics.active,
            activeProfile: data.analytics.active_profile,
            parameters: data.analytics.switch_every
        }))
        await userService.setUserData(data)
    }
    
    return changeData
}

export function useChangeParameter(uid, value) {
    const dispatch = useDispatch();
    const val = value
    const changeParam = async() => {
        const userService = new UserService(uid)
        const data = await userService.getUser()
        data.analytics.switch_every = val
        dispatch(setActiveProfile(data.analytics))
        await userService.setUserData(data)
        
    }
    return changeParam
}

export function useUpdateDispatch(uid) {
    const dispatch = useDispatch();
    const updateDispatch = async() => {
        const userService = new UserService(uid)
        const data = await userService.getUser()
        const activeSubscription = await userService.setUserPremium()
        dispatch(setActiveProfile(data.analytics))
        dispatch(setUserPremium(activeSubscription))
        dispatch(setLoginProfile(data.profile))
    }

    return updateDispatch
}