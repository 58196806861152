import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCXBKRHOkOxEgMsWMDMkkeOEsUYxvrBnBk",
    authDomain: "twirp-ai.firebaseapp.com",
    projectId: "twirp-ai",
    storageBucket: "twirp-ai.appspot.com",
    messagingSenderId: "567963414691",
    appId: "1:567963414691:web:4c997ce0ba536e12260f16",
    measurementId: "G-BH02GQG9FH"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const authentication = getAuth(app);

// const analytics = getAnalytics(app);
