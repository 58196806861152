import { createSlice } from '@reduxjs/toolkit'
import { isEmpty, isNumeric } from '../helper/helper'

const clicksB = isEmpty(window.localStorage.getItem('clicksB')) ? window.localStorage.getItem('clicksB') : 0
const clicksA = isEmpty(window.localStorage.getItem('clicksA')) ? window.localStorage.getItem('clicksA') : 0
const followersA = isEmpty(window.localStorage.getItem('followersA')) ? window.localStorage.getItem('followersA') : 0
const followersB = isEmpty(window.localStorage.getItem('followersB')) ? window.localStorage.getItem('followersB') : 0


const initialState = {
    data: {
        pics: window.localStorage.getItem('pics'),
        names: window.localStorage.getItem('names'),
        bios: window.localStorage.getItem('bios'),
        banners: window.localStorage.getItem('banners'),
        locations: window.localStorage.getItem('locations'),
        websites: window.localStorage.getItem('websites'),
    },
    A: {
        pic: window.localStorage.getItem('picA'),
        name: window.localStorage.getItem('nameA'),
        bio: window.localStorage.getItem('bioA'),
        banner: window.localStorage.getItem('bannerA'),
        location: window.localStorage.getItem('locationA'),
        website: window.localStorage.getItem('websiteA'),
        analytics: {
            clicks: clicksA,
            followers: followersA,
        }
    },
    B: {
        pic: window.localStorage.getItem('picB'),
        name: window.localStorage.getItem('nameB'),
        bio: window.localStorage.getItem('bioB'),
        banner: window.localStorage.getItem('bannerB'),
        location: window.localStorage.getItem('locationB'),
        website: window.localStorage.getItem('websiteB'),
        analytics: {
            clicks: clicksB,
            followers: followersB,
        }
    }
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setPics: (state, action) => {
        state.data.pics = action.payload.pics;
        window.localStorage.setItem('pics', state.data.pics)
    },
    setNames: (state, action) => {
        state.data.names = action.payload.names;
        window.localStorage.setItem('names', state.data.names)
    },
    setBios: (state, action) => {
        state.data.bios = action.payload.bios;
        window.localStorage.setItem('bios', state.data.bios)
    },
    setBanners: (state, action) => {
        state.data.banners = action.payload.banners;
        window.localStorage.setItem('banners', state.data.banners)
    },
    setLocations: (state, action) => {
        state.data.locations = action.payload.locations;
        window.localStorage.setItem('locations', state.data.locations)
    },
    setWebsites: (state, action) => {
        state.data.websites = action.payload.websites;
        window.localStorage.setItem('websites', state.data.websites)
    },
    setAProfile: (state, action) => {
        state.pic = action.payload.pic;
        window.localStorage.setItem('picA', state.pic)
        state.name = action.payload.name;
        window.localStorage.setItem('nameA', state.name)
        state.bio = action.payload.bio;
        window.localStorage.setItem('bioA', state.bio)
        state.banner = action.payload.banner;
        window.localStorage.setItem('bannerA', state.banner)
        state.location = action.payload.location;
        window.localStorage.setItem('locationA', state.location)
        state.website = action.payload.website;
        window.localStorage.setItem('websiteA', state.website)
        state.analytics = action.payload.analytics
        window.localStorage.setItem('clicksA', state.analytics?.clicks)
        window.localStorage.setItem('followersA', state.analytics?.followers)
    },
    setBProfile: (state, action) => {
        state.pic = action.payload.pic;
        window.localStorage.setItem('picB', state.pic)
        state.name = action.payload.name;
        window.localStorage.setItem('nameB', state.name)
        state.bio = action.payload.bio;
        window.localStorage.setItem('bioB', state.bio)
        state.banner = action.payload.banner;
        window.localStorage.setItem('bannerB', state.banner)
        state.location = action.payload.location;
        window.localStorage.setItem('locationB', state.location)
        state.website = action.payload.website;
        window.localStorage.setItem('websiteB', state.website)
        state.analytics = action.payload.analytics
        window.localStorage.setItem('clicksB', state.analytics?.clicks)
        window.localStorage.setItem('followersB', state.analytics?.followers)
    },
    setLoginProfile: (state, action) => {
        state.data.pics = action.payload.data.pics;
        state.data.names = action.payload.data.names;
        state.data.bios = action.payload.data.bios;
        state.data.banners = action.payload.data.banners;
        state.data.locations = action.payload.data.locations;
        state.data.websites = action.payload.data.websites;
        state.A.pic = action.payload.A.pic;
        state.A.name = action.payload.A.name;
        state.A.bio = action.payload.A.bio;
        state.A.banner = action.payload.A.banner;
        state.A.location = action.payload.A.location;
        state.A.website = action.payload.A.website;
        state.A.analytics.clicks = isEmpty(action.payload.A.analytics.clicks) ? 0 : action.payload.A.analytics.clicks;
        state.A.analytics.followers = isEmpty(action.payload.A.analytics.followers) ? 0 : action.payload.A.analytics.followers;
        state.B.pic = action.payload.B.pic;
        state.B.name = action.payload.B.name;
        state.B.bio = action.payload.B.bio;
        state.B.banner = action.payload.B.banner;
        state.B.location = action.payload.B.location;
        state.B.website = action.payload.B.website;
        state.B.analytics.clicks = action.payload.B.analytics.clicks;
        state.B.analytics.followers = action.payload.B.analytics.followers;
        window.localStorage.setItem('pics', state.data?.pics)
        window.localStorage.setItem('names', state.data?.names)
        window.localStorage.setItem('bios', state.data?.bios)
        window.localStorage.setItem('banners', state.data?.banners)
        window.localStorage.setItem('locations', state.data?.locations)
        window.localStorage.setItem('websites', state.data?.websites)
        window.localStorage.setItem('picA', state.A.pic)
        window.localStorage.setItem('nameA', state.A.name)
        window.localStorage.setItem('bioA', state.A.bio)
        window.localStorage.setItem('bannerA', state.A.banner)
        window.localStorage.setItem('locationA', state.A.location)
        window.localStorage.setItem('websiteA', state.A.website)
        window.localStorage.setItem('clicksA', state.A.analytics.clicks)
        window.localStorage.setItem('followersA', state.A.analytics.followers)
        window.localStorage.setItem('picB', state.B.pic)
        window.localStorage.setItem('nameB', state.B.name)
        window.localStorage.setItem('bioB', state.B.bio)
        window.localStorage.setItem('bannerB', state.B.banner)
        window.localStorage.setItem('locationB', state.B.location)
        window.localStorage.setItem('websiteB', state.B.website)
        window.localStorage.setItem('clicksB', state.B.analytics.clicks)
        window.localStorage.setItem('followersB', state.B.analytics.followers)
    },
    setLogoutProfile: (state, action) => {
        state.data.pics = null;
        state.data.names = null;
        state.data.bios = null;
        state.data.banners = null;
        state.data.locations = null;
        state.data.websites = null;
        state.A.pic = null;
        state.A.name = null;
        state.A.bio = null;
        state.A.banner = null;
        state.A.location = null;
        state.A.website = null;
        state.A.analytics.clicks = 0;
        state.A.analytics.followers = 0;
        state.B.pic = null;
        state.B.name = null;
        state.B.bio = null;
        state.B.banner = null;
        state.B.location = null;
        state.B.website = null;
        state.B.analytics.clicks = 0;
        state.B.analytics.followers = 0;
        window.localStorage.setItem('pics', state.data?.pics)
        window.localStorage.setItem('names', state.data?.names)
        window.localStorage.setItem('bios', state.data?.bios)
        window.localStorage.setItem('banners', state.data?.banners)
        window.localStorage.setItem('locations', state.data?.locations)
        window.localStorage.setItem('websites', state.data?.websites)
        window.localStorage.setItem('picA', state.A.pic)
        window.localStorage.setItem('nameA', state.A.name)
        window.localStorage.setItem('bioA', state.A.bio)
        window.localStorage.setItem('bannerA', state.A.banner)
        window.localStorage.setItem('locationA', state.A.location)
        window.localStorage.setItem('websiteA', state.A.website)
        window.localStorage.setItem('clicksA', state.A.analytics.clicks)
        window.localStorage.setItem('followersA', state.A.analytics.followers)
        window.localStorage.setItem('picB', state.B.pic)
        window.localStorage.setItem('nameB', state.B.name)
        window.localStorage.setItem('bioB', state.B.bio)
        window.localStorage.setItem('bannerB', state.B.banner)
        window.localStorage.setItem('locationB', state.B.location)
        window.localStorage.setItem('websiteB', state.B.website)
        window.localStorage.setItem('clicksB', state.B.analytics.clicks)
        window.localStorage.setItem('followersB', state.B.analytics.followers)
    }
  }
});

export const { setPics, setNames, setBios, setBanners, setLocations, setWebsites, setAProfile, setBProfile, setLoginProfile, setLogoutProfile } = profileSlice.actions

export const selectProfileA = state => state?.profile?.A;
export const selectProfileB = state => state?.profile?.B;
export const selectPics = state => state?.profile?.data?.pics;
export const selectNames = state => state?.profile?.data?.names;
export const selectBios = state => state?.profile?.data?.bios;
export const selectBanners = state => state?.profile?.data?.banners;
export const selectWebsites = state => state?.profile?.data?.websites;
export const selectLocations = state => state?.profile?.data?.locations;

export default profileSlice.reducer