import React from 'react'

const Testimonial = (props) => {
    const message = props.info.msg;
    const headline = props.info.headline;
    const person = props.info.person;
    const img = props.info.img;
    const handle = props.info.handle;
    return (
      <div className="card container rounded left-align carousel-card test-h">
        <div className="card-content">
        <div className="row">
          <div className="left">
            <img src={img} alt="" className="circle profile-pic align-img"/>
          </div>
          <div className="col s8">
            <h4 className="bold" >{person}</h4>
            <p className="card-heading test-txt">{handle}</p>
          </div>
        </div>
          <h4 className="bold">{headline}</h4>
          <p className='test-txt'>{message}</p>
        </div>

      </div>
//     <div className="card container">
//     <div className="card-content">
//       <div className="row">
//         <div className="col xl7">
//           <div className="col xl4">
//             <img src={img} alt="" className="circle profile-pic" />
//           </div>
//           <div className="col xl8">
//             <h4 className="extra-bold roboto twitter-link black-text" target="_blank" rel="noopener noreferrer">{person}</h4>
//             <h5 className="roboto twitter-link t-gray-d" target="_blank" rel="noopener noreferrer">{handle}</h5>
//           </div>
//         </div>

//         <div className="col xl3"></div>
//         <div className="col xl1 hide-1500">
//           <FaTwitter size={70} className='t-blue-l'/>
//         </div>
//       </div>
//         <h4 className="message roboto s-margin-l">{message}</h4>
//         <Break h={1}/>
//     </div>
// </div>
  )
}

export default Testimonial